

export const PV_BLANK_VIDEO = {
    p2v_job_id : null,
    p2v_job_name : "",
    prompt  : "",
    article : "",
    language : {language : '' ,code : '' } ,
    format : "",
    slides_count : null ,
    status : '',
    slides_data : [],
    captions_data : [],
    voiceValue : "",
    voiceLabel : ""
    
}