import { PV_BLANK_VIDEO } from "../../../scripts/utils/prompt-to-video/pvConfig";
import { RESET } from "../../document/doc.types"
import { GET_PV_CAPTIONS_ERROR, GET_PV_CAPTIONS_LOADING, GET_PV_CAPTIONS_SUCCESS, GET_PV_CREDITS_ERROR, GET_PV_CREDITS_LOADING, GET_PV_CREDITS_SUCCESS, GET_PV_JOB_DATA_ERROR, GET_PV_JOB_DATA_LOADING, GET_PV_JOB_DATA_SUCCESS, GET_PV_OG_CAPTIONS_ERROR, GET_PV_OG_CAPTIONS_LOADING, GET_PV_OG_CAPTIONS_SUCCESS, GET_PV_OG_SCRIPT_ERROR, GET_PV_OG_SCRIPT_LOADING, GET_PV_OG_SCRIPT_SUCCESS, GET_PV_SCRIPT_LIST_ERROR, GET_PV_SCRIPT_LIST_LOADING, GET_PV_SCRIPT_LIST_SUCCESS, GET_PV_VIDEO_LIST_ERROR, GET_PV_VIDEO_LIST_LOADING, GET_PV_VIDEO_LIST_SUCCESS, INCREMENT_STEP, MODAL_CLOSE, MODAL_OPEN, SET_GLOBAL_USER_DATA } from "./get.types"



const initialState = {
    step : 1,
    modalOpen : false,
    globalUserData : PV_BLANK_VIDEO ,

    get_pv_script_list_loading : false,
    get_pv_script_list_error : false,
    get_pv_script_list_data : null,

    get_pv_video_list_loading : false,
    get_pv_video_list_error : false,
    get_pv_video_list_data : [],
    
    get_pv_og_script_loading : false,
    get_pv_og_script_error : false,
    get_pv_og_script_data : {},

    get_pv_captions_loading : false,
    get_pv_captions_error : false,
    get_pv_captions_data : [],


    get_pv_og_captions_loading : false,
    get_pv_og_captions_error : false,
    get_pv_og_captions_data : [],

    get_pv_credits_loading:false,
    get_pv_credits_error:false,
    get_pv_credits_data:false,


    get_pv_job_data_loading : false,
    get_pv_job_data_error : false,
    get_pv_job_data_data : {},



}

export const getPromptVideoReducer = (state = initialState, { type,payload }) => {
    switch(type){
        case INCREMENT_STEP: return {...state,step: state.step + 1,};

        case MODAL_OPEN : return {...state , modalOpen : true}

        case MODAL_CLOSE : return {...state , modalOpen : false}

        case SET_GLOBAL_USER_DATA : return {...state , globalUserData : payload}

        case GET_PV_SCRIPT_LIST_LOADING: {

            return { ...state, get_pv_script_list_loading: true }
        }

        case GET_PV_SCRIPT_LIST_SUCCESS: {
            return { ...state, get_pv_script_list_loading: false, get_pv_script_list_error: false, get_pv_script_list_data: payload }
        }

        case GET_PV_SCRIPT_LIST_ERROR: {

            return { ...state, get_pv_script_list_loading: false, get_pv_script_list_error: true }
        }


        case GET_PV_OG_SCRIPT_LOADING: {

            return { ...state, get_pv_og_script_loading: true }
        }

        case GET_PV_OG_SCRIPT_SUCCESS: {
            return { ...state, get_pv_og_script_loading: false, get_pv_og_script_error: false, get_pv_og_script_data: payload }
        }

        case GET_PV_OG_SCRIPT_ERROR: {

            return { ...state, get_pv_og_script_loading: false, get_pv_og_script_error: true }
        }

        




        case GET_PV_VIDEO_LIST_LOADING : {
            return {...state , get_pv_video_list_loading:true}
        }

        case GET_PV_VIDEO_LIST_SUCCESS : {
            return {...state , get_pv_video_list_loading : false ,  get_pv_video_list_data : payload,get_pv_video_list_error : false}
        }

        case GET_PV_VIDEO_LIST_ERROR : {
            return {...state , get_pv_video_list_error : true,get_pv_video_list_loading:false}
        }



        // CAPTTIONS
        case GET_PV_CAPTIONS_LOADING: {

            return { ...state, get_pv_captions_loading: true }
        }

        case GET_PV_CAPTIONS_SUCCESS: {
            return { ...state, get_pv_captions_loading: false, get_pv_captions_error: false, get_pv_captions_data: payload }
        }

        case GET_PV_CAPTIONS_ERROR: {

            return { ...state, get_pv_captions_loading: false, get_pv_captions_error: true }
        }


        //OG CAPTIONS
        case GET_PV_OG_CAPTIONS_LOADING: {

            return { ...state, get_pv_og_captions_loading: true }
        }

        case GET_PV_OG_CAPTIONS_SUCCESS: {
            return { ...state, get_pv_og_captions_loading: false, get_pv_og_captions_error: false, get_pv_og_captions_data: payload }
        }

        case GET_PV_OG_CAPTIONS_ERROR: {

            return { ...state, get_pv_og_captions_loading: false, get_pv_og_captions_error: true }
        }


        // CREDITS
        case GET_PV_CREDITS_LOADING: {

            return {...state, get_pv_credits_loading:true}
        }
        case GET_PV_CREDITS_SUCCESS: {

            return {...state, get_pv_credits_loading:false, get_pv_credits_error:false, get_pv_credits_data:payload}
        }
        case GET_PV_CREDITS_ERROR: {

            return {...state, get_pv_credits_loading:false, get_pv_credits_error:true}
        }


        // PARTICULAR JOB DATA
        case GET_PV_JOB_DATA_LOADING: {

            return { ...state, get_pv_job_data_loading: true }
        }

        case GET_PV_JOB_DATA_SUCCESS: {
            return { ...state, get_pv_job_data_loading: false, get_pv_job_data_error: false, get_pv_job_data_data: payload }
        }

        case GET_PV_JOB_DATA_ERROR: {

            return { ...state, get_pv_job_data_loading: false, get_pv_job_data_error: true }
        }






        case RESET: {
            return {...state , step : 1, globalUserData : PV_BLANK_VIDEO, get_pv_script_list_data : null, get_pv_og_script_data : {}, get_pv_captions_data : {}, get_pv_og_captions_data : [], modalOpen : false }
        }

        default: {
            return state
        }
    }
}