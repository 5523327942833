import { RESET } from "../../document/doc.types"
import { DELETE_PV_VIDEO_ERROR, DELETE_PV_VIDEO_LOADING, DELETE_PV_VIDEO_SUCCESS } from "./delete.types"

const initialState = {
    delete_pv_video_loading: false,
    delete_pv_video_error: false
}

export const deletePvReducer = (state=initialState , {type})=>{
    switch(type){
        case DELETE_PV_VIDEO_LOADING: {

            return { ...state, delete_pv_video_loading: true }
        }
        case DELETE_PV_VIDEO_SUCCESS: {

            return { ...state, delete_pv_video_loading: false, delete_pv_video_error: false }
        }
        case DELETE_PV_VIDEO_ERROR: {

            return { ...state, delete_pv_video_loading: false, delete_pv_video_error: true }
        }

        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }
}