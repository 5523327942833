import { RESET } from "../../document/doc.types"
import { PATCH_PV_JOB_API_ERROR, PATCH_PV_JOB_API_LOADING, PATCH_PV_JOB_API_SUCCESS, POST_PV_SUBMIT_JOB_ERROR, POST_PV_SUBMIT_JOB_LOADING, POST_PV_SUBMIT_JOB_SUCCESS, POST_PV_TEXT_TO_AUDIO_ERROR, POST_PV_TEXT_TO_AUDIO_LOADING, POST_PV_TEXT_TO_AUDIO_SUCCESS, POST_PV_VIDEO_JOB_ERROR, POST_PV_VIDEO_JOB_LOADING, POST_PV_VIDEO_JOB_SUCCESS } from "./create.type"

const initialState = {

    post_pv_job_loading:false,
    post_pv_job_error:false,


    text_to_audio_loading: false,
    text_to_audio_error: false,
    text_to_audio_data: false,

    patch_loading: false,
    patch_error: false,

    post_pv_submit_loading : false,
    post_pv_submit_error : false,

}


export const pvCreateReducer =  (state = initialState, { type, payload }) => {
    switch(type){
        case POST_PV_VIDEO_JOB_LOADING: {

            return { ...state, post_pv_job_loading: true }
        }

        case POST_PV_VIDEO_JOB_SUCCESS: {

            return {...state, post_pv_job_loading:false, post_pv_job_error:false}
        }

        case POST_PV_VIDEO_JOB_ERROR: {

            return {...state, post_pv_job_loading:false, post_pv_job_error:true}
        }


        case POST_PV_TEXT_TO_AUDIO_LOADING : {
            return {...state , text_to_audio_loading:true}
        }
        case POST_PV_TEXT_TO_AUDIO_SUCCESS : {
            return {...state , text_to_audio_loading:false , text_to_audio_data:payload}
        }
        case POST_PV_TEXT_TO_AUDIO_ERROR : {
            return {...state , text_to_audio_loading:false, text_to_audio_error:true}
        }



        case PATCH_PV_JOB_API_LOADING: {

            return { ...state, patch_loading: true }
        }

        case PATCH_PV_JOB_API_SUCCESS: {

            return {...state, patch_loading:false, patch_error:false}
        }

        case PATCH_PV_JOB_API_ERROR: {

            return {...state, patch_loading:false, patch_error:true}
        }




        case POST_PV_SUBMIT_JOB_LOADING: {

            return { ...state, post_pv_submit_loading: true }
        }

        case POST_PV_SUBMIT_JOB_SUCCESS: {

            return {...state, post_pv_submit_loading:false, post_pv_submit_error:false}
        }

        case POST_PV_SUBMIT_JOB_ERROR: {

            return {...state, post_pv_submit_loading:false, post_pv_submit_error:true}
        }


        
        case RESET: {
            return initialState
        }

        default: {
            return state
        }
    }
}