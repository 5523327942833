export const STEP = 'step'
export const INCREMENT_STEP = 'INCREMENT_STEP';

export const MODAL_OPEN = 'MODAL_OPEN'
export const MODAL_CLOSE = 'MODAL_CLOSE'


export const SET_GLOBAL_USER_DATA = 'set/global/user/data'


export const GET_PV_SCRIPT_LIST_LOADING = "get/pv/script/list/loading"
export const GET_PV_SCRIPT_LIST_ERROR = "get/pv/script/list/error"
export const GET_PV_SCRIPT_LIST_SUCCESS = "get/pv/script/list/success"


export const GET_PV_VIDEO_LIST_LOADING = "get/pv/video/list/loading"
export const GET_PV_VIDEO_LIST_ERROR = "get/pv/video/list/error"
export const GET_PV_VIDEO_LIST_SUCCESS = "get/pv/video/list/success"


export const GET_PV_OG_SCRIPT_LOADING = "get/pv/og/script/loading"
export const GET_PV_OG_SCRIPT_ERROR = "get/pv/og/script/error"
export const GET_PV_OG_SCRIPT_SUCCESS = "get/pv/og/script/success"



//CAPTIONS
export const GET_PV_CAPTIONS_LOADING = "get/pv/captions/loading"
export const GET_PV_CAPTIONS_ERROR = "get/pv/captions/error"
export const GET_PV_CAPTIONS_SUCCESS = "get/pv/captions/success"


export const GET_PV_OG_CAPTIONS_LOADING = "get/pv/og/captions/loading"
export const GET_PV_OG_CAPTIONS_ERROR = "get/pv/og/captions/error"
export const GET_PV_OG_CAPTIONS_SUCCESS = "get/pv/og/captions/success"


export const GET_PV_CREDITS_LOADING = "get/pv/credits/loading"
export const GET_PV_CREDITS_SUCCESS = "get/pv/credits/success"
export const GET_PV_CREDITS_ERROR = "get/pv/credits/error"


export const GET_PV_JOB_DATA_LOADING = 'get/pv/job/data/loading'
export const GET_PV_JOB_DATA_ERROR = 'get/pv/job/data/error'
export const GET_PV_JOB_DATA_SUCCESS = 'get/pv/job/data/success'