export const POST_PV_VIDEO_JOB_LOADING = "post/pv/video/job/loading"
export const POST_PV_VIDEO_JOB_SUCCESS = "post/pv/video/job/success"
export const POST_PV_VIDEO_JOB_ERROR = "post/pv/video/job/error"




export const POST_PV_TEXT_TO_AUDIO_LOADING = "post/pv/text/to/audio/loading"
export const POST_PV_TEXT_TO_AUDIO_SUCCESS = "post/pv/text/to/audio/success"
export const POST_PV_TEXT_TO_AUDIO_ERROR = "post/pv/text/to/audio/error"



export const PATCH_PV_JOB_API_LOADING = "patch/pv/job/api/loading"
export const PATCH_PV_JOB_API_SUCCESS = "patch/pv/job/api/success"
export const PATCH_PV_JOB_API_ERROR = "patch/pv/job/api/error"



// CAPTIONS
export const POST_PV_SUBMIT_JOB_LOADING = "post/pv/submit/job/loading"
export const POST_PV_SUBMIT_JOB_SUCCESS = "post/pv/submit/job/success"
export const POST_PV_SUBMIT_JOB_ERROR = "post/pv/submit/job/error"


